(function ($) {
  $(function () {

    var totalTabs = $('#project-structure .nav-tabs li').length;
    var fistTab = $('#project-structure .nav-tabs li:first-child a');
    var lastTab = $('#project-structure .nav-tabs li:last-child a');
    var currentTab = 1;



    $('.btnNext').click(function (e) {
      e.preventDefault();
      currentTab += 1;
      showHideControls();
      console.log(currentTab);
      $('.nav-tabs .active').parent().next('li').find('a').trigger('click');
    });

    $('.btnPrevious').click(function (e) {
      e.preventDefault();
      currentTab -= 1;
      showHideControls();
      $('.nav-tabs .active').parent().prev('li').find('a').trigger('click');
    });


    function showHideControls() {

      if (currentTab == 1) {
        $('.btnPrevious').hide();
      } else if (currentTab == totalTabs) {
        $('.btnNext').hide();
      } else {
        $('.btnPrevious').show();
        $('.btnNext').show();
      }

    }
    /*
     * Scroll
     */

    const onScrollID = (_this, offsetValue) => {
      // e.preventDefault();
      // target element id
      var id = _this.attr('href');

      // target element
      var $id = $(id);
      if ($id.length === 0) {
        return;
      }

      var pos = $id.offset().top - offsetValue;

      // animated top scrolling
      $('body, html').animate({
        scrollTop: pos
      });
    }

    $('#primary-menu-mobie li a').on('click', function (e) {
      const _this = $(this)
      onScrollID(_this, 125);
    })

    $(document).on('click', 'a.home-btn, .navbar a[href^="#"]', function (e) {
      const _this = $(this)
      onScrollID(_this, 100);
    });



    /*
     * Navbar fixed 
     */
    $(window).on('scroll', function () {
      if ($(this).scrollTop() > 1) {
        $('.site-header').addClass('navbar-fixed-top');
      } else {
        $('.site-header').removeClass('navbar-fixed-top');
      }
    });

    $('#content').on('shown.bs.collapse', function (e) {

      var panelHeadingHeight = $('.card-header').height();
      var animationSpeed = 250; // animation speed in milliseconds
      var currentScrollbarPosition = $(document).scrollTop();
      var topOfPanelContent = $(e.target).offset().top;

      if (currentScrollbarPosition > topOfPanelContent - panelHeadingHeight) {
        $("html, body").animate({
          scrollTop: topOfPanelContent - panelHeadingHeight - 150
        }, animationSpeed);
      }
    });


    $('.read-more-show').on('click', function (e) {
      e.preventDefault();
      $('.read-more-show').hide();
      $('.read-more-hide').show();
      $('.read-more-text').show();
    });

    $('.read-more-hide').on('click', function (e) {
      e.preventDefault();
      $('.read-more-hide').hide();
      $('.read-more-text').hide();
      $('.read-more-show').show();
    });


    /*
     * Toggle burger menu on mobile
     */
    $('.menu-btn-burger').on('click', function () {
      $('#nav-icon').toggleClass('open-custom-menu');
      // $('body').toggleClass('fixedPosition');
      $(".mobile-menu").toggleClass('sliding-open');
    });

    /* 
     * Owl carousel
     */
    $('.owl-home').owlCarousel({
      margin: 20,
      items: 3,
      loop: true,
      autoplay: true,
      autoplayTimeout: 4500,
      nav: false,
      dots: false,
      animateOut: 'fadeOut',
      // navText: ["<i class='dr-arrow-left'></i>", "<i class='dr-arrow-right'></i>"],
      // navContainer: '#customNav',
      responsive: {
        0: {
          items: 1
        },
        700: {
          items: 2,
          margin: 0,
        },
        1200: {
          items: 3,
        }
      }
    });



    /*
     * Video modal 
     */
    $("body").on("click", "#play-video-modal", function (e) {
      e.preventDefault();
      $("#video-modal").modal("show");
      $("#video-modal iframe")[0].src += "?autoplay=1";
    });

    $("#video-modal").on('hidden.bs.modal', function (e) {
      var videoUrl = $("#video-modal iframe")[0].src;
      videoUrl = videoUrl.replace("?autoplay=1", "");
      $("#video-modal iframe")[0].src = videoUrl;
    });

    /*
     * Open modal
     */

    $('#open-modal').on('click', function (e) {
      e.preventDefault();
      $('#modal').modal('show');
    });

    /*
     * Youtube lazy loading
     */
    $('#play-video-btn').on('click', function (e) {
      e.preventDefault();
      $('#youtubeModal').modal('show');
    });

    $("#youtubeModal").on('hidden.bs.modal', function (e) {
      $("#youtubeModal iframe").attr("src", $("#youtubeModal iframe").attr("src"));
    });



    $('#play-video-btn').on('click', function () {
      var tag = document.createElement('script');
      tag.src = "https://www.youtube.com/iframe_api";
      document.body.appendChild(tag);
    })

    window.onYouTubeIframeAPIReady = function () {
      var videoModules = document.querySelectorAll('.video');
      videoModules = Array.prototype.slice.call(videoModules);
      videoModules.forEach(initializeVideoModule);
    }

    function initializeVideoModule(videoModule) {
      var player = new YT.Player(videoModule.querySelector('.video-placeholder'), {
        videoId: videoModule.dataset.videoId,
        events: {
          onStateChange: function (event) {
            var isEnded = event.data === YT.PlayerState.ENDED;
            videoModule.classList[isEnded ? 'remove' : 'add']('playing');
            if (isEnded) {
              player.destroy();
              videoModule.querySelector('.video-layer').innerHTML = (
                '<div class="video-placeholder"></div>'
              );
              initializeVideoModule(videoModule);
            }
          }
        }
      });
    }

    /*
     * Scroll up button
     */
    $(window).scroll(function () {
      if ($(this).scrollTop() > 150) {
        $('.scroll-to-top').addClass('show');
      } else {
        $('.scroll-to-top').removeClass('show');
        $('.scroll-to-top').addClass('animated bounceOutDown');
        $('.scroll-to-top').removeClass('animated bounceOutUp');
        $('.scroll-to-top i').css('transform', 'rotateY(0) scale(1)');
      }
    });

    $('.scroll-to-top').on("click", function () {
      $('.scroll-to-top').addClass('animated bounceOutUp');
      $('.scroll-to-top i').css('transform', 'rotateY(180deg) scale(1.2)');
      $("html, body").animate({
        scrollTop: 0
      }, 1000);
      return false;
    });

    /*
     * Lightbox gallery
     */
    $('.gallery-images').lightGallery({
      selector: '.gallery-item',
      share: true,
      download: true,
      rotate: false,
      showThumbByDefault: true,
      loop: false,
      slideEndAnimatoin: false,
      thumbnail: true,
      allowMediaOverlap: true,
      toggleThumb: true,
      youtubePlayerParams: {
        loadYoutubeThumbnail: true,
        youtubeThumbSize: 'default',
        modestbranding: 1,
        showinfo: 0,
        rel: 0
      }
    });



    /*
     * Mmenu light 
     */
    var menu = new MmenuLight(
      document.querySelector('#my-menu'),
      'all'
    );
    if (menu) {
      var navigator = menu.navigation({
        // selectedClass: 'Selected',
        // slidingSubmenus: true,
        theme: 'light',
        title: 'Menu'
      });

      var drawer = menu.offcanvas({
        position: 'right'
      });

      //  Open the menu.
      document.querySelector('#nav-icon')
        .addEventListener('click', evnt => {
          evnt.preventDefault();
          drawer.open();
        });

      $(".mob-menu-close").click(function () {
        drawer.close();
      });
    }


    $('.mm-ocd li').on('click', function () {
      drawer.close();
    });




  });
})(jQuery);